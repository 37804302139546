import React from 'react'
import { Row, Col, Card, Button } from 'react-materialize'
import { AlertTriangle } from 'react-feather'

const ErrorPage = (props: ErrorPageDataInterface) => {
  return (
    <Row>
      <Col l={6} s={12} push="l3">
        <Card className="center-align">
          <div className="section">
            {props.useSVG ? (
              <AlertTriangle size={80} stroke="#e34c4c" />
            ) : (
              <img src={props.imageLink} alt={props.imageAlt} />
            )}
          </div>
          <div className="section">
            <h3>{props.header}</h3>
            <p>{props.description}</p>
          </div>
          <div className="section">
            <Button node="a" href="/">
              {props.linkToHome}
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default ErrorPage
